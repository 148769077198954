<template>
  <div class="auth-wrapper auth-v1 warpperContent">
    <v-row>
      <v-col md="6" sm="6" cols="12">
        <v-card>
          <v-card-text>
            <div class="d-flex align-center">
              <img src="../../assets/images/other/laptop-2298286.svg" alt="laptop" class="img_bg" />
              <p class="mb-0 ms-4 font-weight-bold tag_pSize">Shop Za</p>
            </div>
            <div class="d-flex justify-space-between align-center mt-5 billHeader">
              <p class="mb-0">บิลเลขที่ {{ this.$store.state.numberBill }}</p>
              <a href="#">ใบเสร็จ</a>
            </div>
            <v-row>
              <!-- ชื่อลูกค้า -->
              <v-col md="6" sm="6" cols="12" class="mt-3 pb-0">
                <p class="mb-0">ชื่อลูกค้า</p>
              </v-col>
              <v-col md="6" sm="6" cols="12" class="mt-3 d-flex justify-end pb-0">
                <p class="mb-0 font-weight-bold">{{ this.$store.state.nameCustomer }}</p>
              </v-col>
              <!-- วันที่ออกบิล -->
              <v-col md="6" sm="6" cols="12" class="pt-1 pb-0">
                <p class="mb-0">ออกบิลเมื่อ</p>
              </v-col>
              <v-col md="6" sm="6" cols="12" class="pt-1 pb-0 d-flex justify-end">
                <p class="mb-0">{{ this.$store.state.timePay }}</p>
              </v-col>
              <!-- วันที่ออกบิล -->
              <v-col md="6" sm="6" cols="12" class="pt-1 pb-0">
                <p class="mb-0">กรุณาชำระก่อน</p>
              </v-col>
              <v-col md="6" sm="6" cols="12" class="pt-1 pb-0 d-flex justify-end">
                <p class="mb-0">{{ this.$store.state.finalPay }}</p>
              </v-col>
            </v-row>
            <hr class="mt-8 mb-2" />
            <li v-for="item in this.$store.state.mockData" :key="item.id">
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  <img :src="item.img" alt="image product" />
                  <div>
                    <p class="mb-0">{{ item.nameProduct }}</p>
                    <p class="mb-0">จำนวน {{ item.amount }}</p>
                  </div>
                </div>
                <p class="d-flex align-center">{{ item.price }} บาท</p>
              </div>
              <hr class="mt-2 mb-2" />
            </li>
            <v-row>
              <!-- มูลค่าสินค้า -->
              <v-col md="6" sm="6" cols="12" class="pb-1">
                <p class="mb-0">มูลค่าสินค้า</p>
              </v-col>
              <v-col md="6" sm="6" cols="12" class="pb-1">
                <p class="mb-0 d-flex justify-end">{{ total }} บาท</p>
              </v-col>
              <!-- จบมูลค่าสินค้า -->

              <!-- มูลค่าสินค้า -->
              <v-col md="6" sm="6" cols="12" class="pt-0 pb-1">
                <p class="mb-0 pb-0 pt-0">ค่าจัดส่ง</p>
              </v-col>
              <v-col md="6" sm="6" cols="12" class="pt-0 pb-1">
                <p class="mb-0 pb-0 pt-0 d-flex justify-end">0 บาท</p>
              </v-col>
              <!-- จบมูลค่าสินค้า -->

              <!-- มูลค่าสินค้า -->
              <v-col md="6" sm="6" cols="12" class="pt-0 pb-0">
                <p class="mb-0">ส่วนลด</p>
              </v-col>
              <v-col md="6" sm="6" cols="12" class="pt-0 pb-0">
                <p class="mb-0 d-flex justify-end">0 บาท</p>
              </v-col>
              <!-- จบมูลค่าสินค้า -->

              <!-- ยอดสุทธิ -->
              <v-col md="6" sm="6" cols="12" class="pt-3 pb-0">
                <h3 class="mb-0">ยอดสุทธิ</h3>
              </v-col>
              <v-col md="6" sm="6" cols="12" class="pt-3 pb-0">
                <h2 class="mb-0 d-flex justify-end">{{ total }} บาท</h2>
              </v-col>
              <!-- จบยอดสุทธิ -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="6" cols="12">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              ชำระเงิน
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              การจัดส่ง
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 3" step="3">
              เตรียมส่ง
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4">
              ระหว่างขนส่ง
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- step 1 -->
            <v-stepper-content step="1">
              <div class="mb-8 text-center" color="grey lighten-1">
                <div class="d-flex justify-space-between">
                  <p class="mb-0 textStatus">สถานะ</p>
                  <p class="mb-0 textNotPay">ยังไม่จ่าย</p>
                </div>
                <div class="text-center mt-2 contentStep1">
                  <div class="d-flex justify-center align-center">
                    <v-icon size="35">{{ icons.mdiHeadset }}</v-icon>
                  </div>
                  <p class="mb-0 mt-2">ไม่มีช่องทางการรับชำระ</p>
                </div>
                <p class="mb-2 mt-5">ติดต่อร้านค้า เพื่อสอบถามช่องทางการรับชำระ</p>
                <v-btn color="secondary">ติดต่อร้านค้า</v-btn>
              </div>
              <div class="d-flex justify-end">
                <v-btn color="primary" @click="e1 = 2">
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>
            <!-- end step 1 -->

            <!-- step 2 -->
            <v-stepper-content step="2">
              <div class="mb-8" color="grey lighten-1">
                <p class="mb-3 textStatus">การจัดส่ง</p>
                <p class="mb-0">รายละเอียดการจัดส่ง</p>
                <hr class="mt-5 mb-5" />
                <!-- ชื่อผู้รับ -->
                <p class="mb-2">ชื่อผู้รับ</p>
                <v-text-field
                  v-model="this.$store.state.nameCustomer"
                  label="ชื่อผู้รับ"
                  type="text"
                  outlined
                  dense
                  hide-details
                  placeholder="ชื่อผู้รับ"
                ></v-text-field>
                <!-- จบชื่อผู้รับ -->

                <!-- เบอร์ติดต่อ -->
                <p class="mb-2 mt-2">เบอร์ติดต่อ</p>
                <v-text-field
                  v-model="this.$store.state.phoneNumberCustomer"
                  label="เบอร์ติดต่อ"
                  type="text"
                  outlined
                  dense
                  hide-details
                  placeholder="เบอร์ติดต่อ"
                ></v-text-field>
                <!-- จบเบรอ์ติดต่อ -->

                <!-- ทีอยู่จัดส่ง -->
                <p class="mb-2 mt-2">ที่อยู่จัดส่ง</p>
                <v-textarea
                  v-model="this.$store.state.addressCustomer"
                  label="ที่อยู่จัดส่ง"
                  hide-details
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                ></v-textarea>
                <!-- ที่อยู่จัดส่ง -->
                <v-btn color="secondary" class="mt-3 confirm_address">ยืนยันที่อยู่จัดส่ง</v-btn>
                <hr class="mt-8" />
              </div>

              <div class="d-flex justify-end">
                <v-btn color="primary" @click="e1 = 3">
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>
            <!-- end step 2 -->

            <!-- step 3 -->
            <v-stepper-content step="3">
              <div class="mb-12" color="grey lighten-1">
                <div class="text-center mt-2 contentStep3">
                  <div class="d-flex justify-center align-center">
                    <v-icon size="35">{{ icons.mdiCheckboxMarkedCircleOutline }}</v-icon>
                  </div>
                  <p class="mb-0 mt-2">ร้านได้รับเงินแล้ว อยู่ในขั้นตอนจัดส่ง</p>
                </div>
                <!-- ติดตามพัสดุ -->
                <h3 class="mb-0 mt-3">รหัสพัสดุ</h3>
                <p class="mb-0">{{ this.$store.state.codeFollow }}</p>
                <!-- จบติดตามพัสดุ -->
                <!-- รายละเอียดจัดส่ง -->
                <div class="d-flex justify-space-between align-center mt-5">
                  <p class="mb-0">รายละเอียดจัดส่ง</p>
                  <p v-if="!editAddress" class="mb-0 textEdit" @click="clickEditData">แก้ไข</p>
                </div>
                <hr class="mt-3 mb-3" />
                <h4>{{ this.$store.state.nameCustomer }}</h4>
                <p>{{ this.$store.state.addressCustomer }}</p>
                <div v-if="editAddress">
                  <p class="mb-2 mt-2">ที่อยู่จัดส่ง</p>
                  <v-textarea
                    v-model="this.$store.state.addressCustomer"
                    label="ที่อยู่จัดส่ง"
                    hide-details
                    auto-grow
                    outlined
                    rows="1"
                    row-height="15"
                  ></v-textarea>
                  <!-- จบที่อยู่จัดส่ง -->
                  <!-- เบอร์ติดต่อ -->
                  <p class="mb-2 mt-2">เบอร์ติดต่อ</p>
                  <v-text-field
                    v-model="this.$store.state.phoneNumberCustomer"
                    label="เบอร์ติดต่อ"
                    type="text"
                    outlined
                    dense
                    hide-details
                    placeholder="เบอร์ติดต่อ"
                  ></v-text-field>
                  <!-- จบเบอร์ติดต่อ -->
                  <v-btn color="info" class="mt-3 confirm_address" @click="clickEditData">ยืนยันที่อยู่จัดส่ง</v-btn>
                </div>
                <!-- จบรายละเอียดจัดส่ง -->
              </div>

              <div class="d-flex justify-end">
                <v-btn color="primary" @click="e1 = 4">
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>
            <!-- end step 3 -->

            <!-- step 4 -->
            <v-stepper-content step="4">
              <div class="mb-5" color="grey lighten-1">
                <div class="text-center mt-2 contentStep4">
                  <div class="d-flex justify-center align-center">
                    <v-icon size="35">{{ icons.mdiThumbUp }}</v-icon>
                  </div>
                  <p class="mb-0 mt-2">ให้คะแนนความพึงพอใจ สินค้าที่สั่งซื้อ</p>
                </div>
              </div>
              <!-- เขียนรีวิว -->
              <p class="mb-0">ให้คะแนนรีวิว</p>
              <v-btn text @click="gotoReview()" color="warning" class="me-2">
                <p class="mb-0">เขียนรีวิว</p>
              </v-btn>
              <!-- จบเขียนรีวิว -->
              <!-- ติดตามพัสดุ -->
              <h3 class="mb-0 mt-3">รหัสพัสดุ</h3>
              <p class="mb-0">{{ this.$store.state.codeFollow }}</p>
              <!-- จบติดตามพัสดุ -->
              <!-- รายละเอียดจัดส่ง -->
              <div class="d-flex justify-space-between align-center mt-5">
                <p class="mb-0">รายละเอียดจัดส่ง</p>
                <p v-if="!editAddress" class="mb-0 textEdit" @click="clickEditData">แก้ไข</p>
              </div>
              <hr class="mt-3 mb-3" />
              <h4>{{ this.$store.state.nameCustomer }}</h4>
              <p>{{ this.$store.state.addressCustomer }}</p>
              <div v-if="editAddress" class="mb-3">
                <p class="mb-2 mt-2">ที่อยู่จัดส่ง</p>
                <v-textarea
                  v-model="this.$store.state.addressCustomer"
                  label="ที่อยู่จัดส่ง"
                  hide-details
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                ></v-textarea>
                <!-- จบที่อยู่จัดส่ง -->
                <!-- เบอร์ติดต่อ -->
                <p class="mb-2 mt-2">เบอร์ติดต่อ</p>
                <v-text-field
                  v-model="this.$store.state.phoneNumberCustomer"
                  label="เบอร์ติดต่อ"
                  type="text"
                  outlined
                  dense
                  hide-details
                  placeholder="เบอร์ติดต่อ"
                ></v-text-field>
                <!-- จบเบอร์ติดต่อ -->
                <v-btn color="info" class="mt-3 confirm_address" @click="clickEditData">ยืนยันที่อยู่จัดส่ง</v-btn>
              </div>
              <!-- จบรายละเอียดจัดส่ง -->

              <!-- <div class="d-flex justify-end">
              <v-btn color="primary" @click="e1 = 1">
                Continue
              </v-btn>
            </div> -->
              <v-btn color="secondary" class="mt-3 confirm_address text-address" @click="e1 = 1"
                >บันทึกข้อมูลจัดส่ง</v-btn
              >
            </v-stepper-content>
          </v-stepper-items>
          <!-- end step 4 -->
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiHeadset, mdiCheckboxMarkedCircleOutline, mdiThumbUp } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiHeadset,
        mdiCheckboxMarkedCircleOutline,
        mdiThumbUp,
      },
    }
  },
  data() {
    return {
      e1: 1,
      priceProduct1: 100,
      priceProduct2: 50,
      editAddress: false,
    }
  },
  computed: {
    total() {
      return this.priceProduct1 + this.priceProduct2
    },
  },
  methods: {
    clickEditData() {
      this.editAddress = !this.editAddress
    },
    gotoReview() {
      const allPage = this.$router.resolve({ path: '/pages/review' })
      window.open(allPage.href)
    },
  },
}
</script>

<style scoped>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
li {
  list-style-type: none;
}
img {
  height: 5.15rem;
  width: 4.235rem;
  border-radius: 50%;
  transform: translateX(-10px);
}
.img_bg {
  background-color: #78a2cc;
  width: 5.25rem;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.tag_pSize {
  font-size: 20px;
}
.billHeader p {
  font-size: 20px;
  font-weight: 600;
}
.billHeader a {
  text-decoration: none;
}
.textStatus {
  font-weight: 600;
  font-size: 20px;
}
.textNotPay {
  color: red !important;
  font-size: 20px;
  font-weight: 600;
}
.contentStep1 {
  background-color: #fddb96;
  width: 100%;
  padding: 3rem 0;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.confirm_address {
  width: 100%;
}
.text-address {
  color: #f1f1f1;
}
.contentStep3 {
  background-color: #bee5b0;
  width: 100%;
  padding: 3rem 0;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.textEdit {
  color: #8dbc8a;
  cursor: pointer;
}
.contentStep4 {
  background-color: #bae1ff;
  width: 100%;
  padding: 3rem 0;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.warpperContent {
  padding: 0 2rem;
}

@media screen and (max-width: 600px) {
  .warpperContent {
    padding: 2rem 2rem;
  }
}
</style>
